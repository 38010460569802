<template>
  <div>
    <b-overlay :show="isFetching">
      <b-card>
        <validation-observer
          ref="promotionForm"
        >
          <b-toast
            :visible="isFailed"
            title="Warning"
            variant="warning"
          >
            {{ $t('messages.invalid_form') }}
          </b-toast>
          <form
            autocomplete="off"
            @submit.prevent="onSubmit"
          >
            <agent-select-input
              v-if="promotionId === null"
              :value="promotionData.agentId"
              label-cols="2"
              @update="(agentId) => promotionData.agentId = agentId"
            />
            <validation-provider
              v-slot="{ errors }"
              :name="$t('promotion.name')"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                :label="`${$t('promotion.name')} *`"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="promotionData.title"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
            <b-form-group
              :label="`${$t('fields.description')} *`"
              label-cols-sm="12"
              label-cols-lg="2"
            >
              <vue-editor v-model="promotionData.description" />
            </b-form-group>
            <b-form-group 
              label="รูปภาพ"
              label-cols-sm="12"
              label-cols-lg="2"
              label-for="file"
            >
              <b-form-file
                id="file"
                v-model="promotionData.image"
                accept="image/*"
              />
              <b-form-text>
                <b-link
                  v-if="promotionData.image"
                  class="text-danger"
                  @click="promotionData.image = ''"
                >
                  {{ $t('buttons.remove') }}
                </b-link>
                <b-link
                  v-if="promotionId && promotionDetail.imageUrl"
                  :href="promotionDetail.imageUrl"
                  target="_blank"
                  class="text-info"
                >
                  กดดูรูปภาพ
                </b-link>
              </b-form-text>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              label="ชนิดเกมส์"
              label-for="forGameType"
            >
              <b-select
                id="forGameType"
                v-model="promotionData.forGameType"
              >
                <b-form-select-option
                  v-for="(opt, key) in gameTypeOptions"
                  :key="key"
                  :value="opt.value"
                >
                  {{
                    $te(`promotion.types.${opt.text}`)
                      ? $t(`promotion.types.${opt.text}`)
                      : opt.text
                  }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('fields.type')"
              label-for="type"
            >
              <b-select
                id="type"
                v-model="promotionData.type"
              >
                <b-form-select-option
                  v-for="(opt, key) in promotionOptions"
                  :key="key"
                  :value="opt.value"
                >
                  {{
                    $te(`promotion.types.${opt.text}`)
                      ? $t(`promotion.types.${opt.text}`)
                      : opt.text
                  }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              label="ประเภทโบนัส"
              label-for="bonusType"
            >
              <b-select
                id="bonusType"
                v-model="promotionData.bonusType"
                :disabled="![0,5,6,7].includes(promotionData.bonusType)"
              >
                <b-form-select-option
                  v-for="(opt, key) in bonusOptions"
                  :key="key"
                  :value="opt.value"
                >
                  {{ opt.text }}
                </b-form-select-option>
              </b-select>
            </b-form-group>
            <b-form-group
              v-if="[7, 8].includes(promotionData.type)"
              label-cols-sm="12"
              label-cols-lg="2"
              label="ระยะเวลาต่อเนื่อง"
            >
              <b-input-group append="วัน">
                <b-input
                  v-model="promotionData.stackDayCount"
                  min="1"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if="promotionData.type === 4"
              label-cols-sm="12"
              label-cols-lg="2"
              label="เวลานาทีทอง"
            >
              <b-row>
                <b-col>
                  <b-form-group label="เริ่มเวลา">
                    <b-form-timepicker
                      v-model="startTimeRefill"
                      locale="en"
                      :hour12="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="จบเวลา">
                    <b-form-timepicker
                      v-model="endTimeRefill"
                      locale="en"
                      :hour12="false"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="จำกัดแจกต่อวัน"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="จำกัดต่อวัน"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="promotionData.dailyLimit"
                  type="number"
                  :state="errors[0] ? false : null"
                />
                <b-form-text>
                  ไม่จำกัดใส่ 0
                </b-form-text>
              </b-form-group>
            </validation-provider>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('cashback.turnover_point')"
            >
              <b-row class="mb-3">
                <b-col>
                  <b-input-group prepend="ประเภทเทิร์น">
                    <b-select
                      id="turnType"
                      v-model="promotionData.turnType"
                    >
                      <b-form-select-option
                        v-for="(opt, key) in turnTypeOptions"
                        :key="key"
                        :value="opt.value"
                      >
                        {{ opt.text }}
                      </b-form-select-option>
                    </b-select>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-input-group prepend="ยอดเทิร์น">
                    <b-input
                      v-model="promotionData.turnRate"
                      type="number"
                    />
                  </b-input-group>
                  <b-form-text>
                    ระบบจะนำยอดฝากกับโบนัสเอาไปคูณ เช่นเทิร์น 10 เท่า ฝาก 100
                    โบนัส 50 ลูกค้าต้องทำเทิร์น 1500
                  </b-form-text>
                </b-col>
                <b-col>
                  <b-input-group prepend="อั้นถอน (ถ้าไม่อั้นใส่ 0)">
                    <b-input
                      :v-model="promotionData.maxWithdraw || 0"
                      type="number"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <validation-provider
              v-if="[0].includes(promotionData.type)"
              v-slot="{ errors }"
              name="โบนัส"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="โบนัส"
                label-for="bonus"
              >
                <b-form-input
                  id="bonus"
                  v-model="promotionData.promotionBonuses[0].bonus"
                  type="number"
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
            </validation-provider>
            <b-form-group
              v-else
              label-cols-lg="2"
              label-cols-sm="12"
              :label="promotionData.type === 5 || promotionData.type === 6?'รางวัล':'เงื่อนไข'"
            >
              <b-row
                v-for="(item, index) in promotionData.promotionBonuses"
                :key="index"
              >
                <b-col>
                  <b-form-group label="ฝากต่ำสุด">
                    <b-form-input v-model="item.minDeposit" />
                  </b-form-group>
                </b-col>
                <b-col v-if="promotionData.type === 7">
                  <b-form-group label="ฝากสูงสุด">
                    <b-form-input :v-model="item.maxDeposit || 0" />
                    <b-form-text>(ไม่จำกัดใส่ 0)</b-form-text>
                  </b-form-group>
                </b-col>
                <b-col v-if="promotionData.bonusType === 2">
                  <b-form-group label="%การออก">
                    <b-form-input
                      v-model="item.percentage"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="โบนัส">
                    <b-form-input v-model="item.bonus" />
                  </b-form-group>
                </b-col>
                <b-col v-if="promotionData.type === 7">
                  <b-form-group label="โบนัสสูงสุด">
                    <b-form-input
                      :v-model="item.maxBonus || 0"
                    />
                    <b-form-text>(ถ้าไม่อั้นใส่ 0)</b-form-text>
                  </b-form-group>
                </b-col>
                <b-col cols="1">
                  <b-form-group :label="$t('buttons.remove')">
                    <b-button
                      :disabled="[0, 7].includes(promotionData.type)"
                      variant="danger"
                      @click="onRemoveConditionSetting(index)"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                      />
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    size="sm"
                    :disabled="[0, 7].includes(promotionData.type)"
                    @click="onAddCondition"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    {{ $t('buttons.add') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              abel-cols-sm="12"
              label-cols-lg="2"
              label-for="isHide"
            >
              <b-input-group>
                <b-form-checkbox
                  v-model="promotionData.isHide"
                  name="isHide"
                  switch
                >
                  แสดงหน้าโปรโมชั่น
                </b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <b-form-group
              abel-cols-sm="12"
              label-cols-lg="2"
              label-for="isActive"
            >
              <b-input-group>
                <b-form-checkbox
                  v-model="promotionData.isActive"
                  name="isActive"
                  switch
                >
                  เปิดใช้งาน
                </b-form-checkbox>
              </b-input-group>
            </b-form-group>
            <div class="text-right">
              <b-button
                class="mr-2"
                :to="{ name: 'VIEW_PROMOTIONS'}"
                variant="light"
              >
                {{ $t('buttons.back') }}
              </b-button>
              <b-overlay
                :show="isAdding || isUpdating"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'

export default {
  page: {
    title: 'Promotion',
  },
  components: {
    VueEditor, 
  },
  data() {
    return {
      required,
      isFailed: false,

      startTimeRefill: null,
      endTimeRefill: null,
      // ประเภทโปร (type)
      // ฟรีเครดิต = 0
      // ฝากครั้งแรก = 1
      // ฝากครั้งแรกของวัน = 2
      // ทุกยอดฝาก = 3
      // นาทีทอง = 4
      // กงล้อ = 5
      // เปิดไพ่ = 6
      promotionOptions: [
        { text: 'ฟรีเครดิต', value: 0 },
        { text: 'ฝากครั้งแรก', value: 1 },
        { text: 'ฝากครั้งแรกของวัน', value: 2 },
        { text: 'ทุกยอดฝาก', value: 3 },
        { text: 'นาทีทอง', value: 4 },
        { text: 'กงล้อ', value: 5 },
        { text: 'เปิดไพ่', value: 6 },
        { text: 'เช็คอิน', value: 7 },
        { text: 'Welcome Back', value: 8 },
      ],
      // เกม (forGameType)
      // ทุกเกม = 0
      // สล็อต = 1
      // ยิงปลา = 2
      // คาสิโน = 3
      gameTypeOptions: [
        { text: 'ทุกเกม', value: 0 },
        { text: 'สล็อต', value: 1 },
        { text: 'ยิงปลา', value: 2 },
        { text: 'คาสิโน', value: 3 },
      ],
      // ประเภทโบนัส (bonusType)
      // เปอร์เซ็นต์ = 0
      // ค่าคงที่ = 1
      // สุ่ม = 2
      bonusOptions: [
        { text: 'เปอร์เซ็นต์', value: 0 },
        { text: 'ค่าคงที่', value: 1 },
        { text: 'สุ่ม', value: 2 },
      ],
      // ประเภทเทิร์น (turnType)
      // เทิร์นเครดิต = 0
      // เทิร์นโอเวอร์ = 1
      // ไม่มีเทิร์น = 2
      turnTypeOptions: [
        { text: 'เทิร์นเครดิต', value: 0 },
        { text: 'เทิร์นโอเวอร์', value: 1 },
        { text: 'ไม่มีเทิร์น', value: 2 },
      ],
      promotionData: {
        title: '',
        description: '',
        image: null,
        type: 0,
        conditionType: 0,
        maxDeposit: 0,
        minDeposit: 0,
        forGameType: 0,
        bonusType: 0,
        stackDayCount: 1,
        turnType: 0,
        turnRate: 0,
        maxBonus: 0,
        maxWithdraw: 0,
        startMin: 0,
        startHour: 0,
        endHour: 0,
        endMin: 0,
        dailyLimit: 0,
        isHide: false,
        isActive: false,
        startTime: 9,
        endTime: 9,
        promotionBonuses: [
          {
            bonus: 100,
            maxBonus: 0,
            minDeposit: 100,
            maxDeposit: 0,
            percentage: 0,
          }
        ],
        agentId: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.promotion.isFetchingPromotion,
      isAdding: (state) => state.promotion.isAddingPromotion,
      isUpdating: (state) => state.promotion.isUpdatingPromotion,
    }),
    ...mapGetters(['promotionDetail', 'userInfo', 'isAgent']),
    isLoading() {
      return false
    },
    promotionId() {
      return this.$route.params.id === 'create' ? null : this.$route.params.id
    },
  },
  watch: {
    'promotionData.type'(val){
      // กงล้อ เปิด ไพ่ ฟิกไว้ว่าต้องสุ่มอย่างเดียว
      if(val === 5 || val === 6){
        this.promotionData.bonusType = 2
      } else if(val === 7) {
        this.promotionData.bonusType = 1
        this.promotionData.promotionBonuses = [
          {
            bonus: 100,
            maxBonus: 0,
            minDeposit: 500,
            maxDeposit: 0,
            percentage: 0,
          }
        ]
      } else if(val === 0) {
        this.promotionData.bonusType = 1
        this.promotionData.promotionBonuses = [
          {
            bonus: 100,
            maxBonus: 0,
            minDeposit: 0,
            maxDeposit: 0,
            percentage: 0,
          }
        ]
      } else {
        this.promotionData.bonusType = 0
      }
    },
    promotionDetail(val) {
      if (val && !this.promotionData.id) {
        this.setData()
      }
    },
    startTimeRefill(time) {
      if (time) {
        this.promotionData.startHour = time.split(':')[0]
        this.promotionData.startMin = time.split(':')[1]
      }
    },
    endTimeRefill(time) {
      if (time) {
        this.promotionData.endHour = time.split(':')[0]
        this.promotionData.endMin = time.split(':')[1]
      }
    },
  },
  async created() {
    if (this.promotionId) {
      await this.fetchPromotion(this.promotionId)
    }
    this.setData()
    if (this.isAgent) {
      this.promotionData.agentId = this.userInfo.agentId
    }
  },
  methods: {
    ...mapActions(['fetchPromotion', 'addPromotion', 'updatePromotion']),
    setData() {
      if (this.promotionId && this.promotionDetail) {
        const data = JSON.parse(JSON.stringify(this.promotionDetail))

        this.promotionData = { ...data }

        this.startTimeRefill = `${
          String(data.startHour).length === 1
            ? `0${data.startHour}`
            : data.startHour
        }:${
          String(data.startMin).length === 1
            ? `0${data.startMin}`
            : data.startMin
        }:00`
        this.endTimeRefill = `${data.endHour}:${data.endMin}:00`
        this.endTimeRefill = `${
          String(data.endHour).length === 1 ? `0${data.endHour}` : data.endHour
        }:${
          String(data.endMin).length === 1 ? `0${data.endMin}` : data.endMin
        }:00`

        if(data.promotionBonuses && data.promotionBonuses.length > 0){
          this.promotionData.promotionBonuses = []
          data.promotionBonuses.forEach((prop) => {
            this.promotionData.promotionBonuses.push(prop)
          })
        } else {
          this.promotionData.promotionBonuses = [{
            bonus: 100,
            maxBonus: 100,
            minDeposit: 100,
            maxDeposit: 100,
            percentage: 0,
          }]
        }
      } else {
        this.startTimeRefill = '10:00:00'
        this.endTimeRefill = '12:00:00'
        this.promotionData = {
          title: '',
          description: '',
          type: 0,
          conditionType: 0,
          maxDeposit: 0,
          minDeposit: 0,
          forGameType: 0,
          bonusType: 0,
          turnType: 0,
          turnRate: 0,
          maxWithdraw: 0,
          stackDayCount: 1,
          maxBonus: 0,
          dailyLimit: 0,
          image: null,
          promotionBonuses: [
            {
              bonus: 100,
              maxBonus: 0,
              minDeposit: 100,
              maxDeposit: 0,
              percentage: 0,
            },
          ],
          startMin: 0,
          startHour: 0,
          endHour: 0,
          endMin: 0,
        }
      }
    },
    onSubmit() {
      this.$refs.promotionForm.validate().then( async (success) => {
        if (success) {
          this.promotionData.turnRate = +this.promotionData.turnRate
          if (this.promotionId) {
            this.updatePromotion({
              id: this.promotionId,
              data: this.promotionData,
            })
          } else {
            this.addPromotion(this.promotionData)
          }
        } else {
          this.isFailed = true
        }
      })
      
    },
    onRemoveConditionSetting(index = 0) {
      this.promotionData.promotionBonuses.splice(index, 1)
    },
    onAddCondition() {
      this.promotionData.promotionBonuses.push({
        bonus: 100,
        maxBonus: 0,
        minDeposit: 100,
        maxDeposit: 0,
        percentage: 0,
      })
    },
  },
}
</script>
